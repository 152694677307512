(<template>
  <div class="travel-rates">
    <div v-if="!isEditable"
         class="travel-rates__cont travel-rates__cont--selected">
      <span :class="[`travel-rates__btn-text--${travelTypeSelected || 'no_travel'}`]"
            class="travel-rates__btn-text">{{ selectedTravelTypeText }}</span>
    </div>
    <div v-if="isEditable"
         class="travel-rates__cont travel-rates__cont--editable">
      <div class="travel-rates__rates">
        <button v-for="button in filteredButtonsList"
                :key="button.name"
                :class="{'travel-rates__btn--active': button.active}"
                class="travel-rates__btn"
                @click="saveTravelType(button.name)">
          <span :class="[`travel-rates__btn-text--${button.name}`]"
                class="travel-rates__btn-text">{{ button.text }}</span>
        </button>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    props: {
      isEditable: Boolean,
      travelTypeSelected: {
        type: String,
        default: () => {
          return '';
        }
      }
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        allowedTravelTypes: ({storeJobObj}) => {
          return storeJobObj.priceRequirement ? storeJobObj.priceRequirement.allowedTravelTypes || [] : [];
        }
      }),
      ...mapState('AssignmentFlowStore', {
        travelType: ({travelType}) => travelType
      }),
      isNoTravel() { return this.travelType === 'no_travel'; },
      isTravelCar() { return this.travelType === 'car'; },
      isTravelPublicTransport() { return this.travelType === 'public_transport'; },
      buttonsList() {
        return [
          {
            name: 'no_travel',
            value: '',
            text: this.$gettext('I am already at this location'),
            condition: this.showButton('public_transport') || this.showButton('public_transport'),
            active: this.isNoTravel
          },
          {
            name: 'public_transport',
            value: 'public_transport',
            text: this.$gettext('Public transport'),
            condition: this.showButton('public_transport'),
            active: this.isTravelPublicTransport
          },
          {
            name: 'car',
            value: 'car',
            text: this.$gettext('Driving'),
            condition: this.showButton('car'),
            active: this.isTravelCar
          }
        ];
      },
      filteredButtonsList() {
        return this.buttonsList.filter(({condition}) => condition);
      },
      selectedTravelTypeText() {
        const template = this.travelTypeSelected
          ? this.$gettext('I choose to travel by %{type}')
          : this.$gettext('I choose not to travel');
        return this.$gettextInterpolate(template, {type: this.getTravelTypeText(this.travelTypeSelected)});
      }
    },
    methods: {
      showButton(buttonName) {
        return this.allowedTravelTypes.includes(buttonName);
      },
      saveTravelType(travelType) {
        const params = travelType ? {travel_by: travelType} : {};
        if (this.travelType === travelType) return false;
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('AssignmentFlowStore/getInterInvoicePreviewData', {
          jobId: this.$route.params.id,
          params
        }).then(() => {
          this.$emit('traveltypechanged', travelType);
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
      getTravelTypeText(type) {
        switch (type) {
          case 'public_transport':
            return this.$gettext('public transport');
          case 'car':
            return this.$gettext('car');
          default:
            return type;
        }
      }
    }
  };
</script>

<style scoped>
  .travel-rates__cont {
    display: flex;
    align-items: center;
  }

  .travel-rates__cont--editable {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }

  .travel-rates__cont--selected {
    margin: 15px 0;
    padding: 0 5px;
  }

  .travel-rates__rates {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  .travel-rates__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 20px;
    padding: 5px;
    border: solid 1px #ff5b24;
    border: solid 1px var(--color-secondary);
    border-radius: 3px;
    background-color: #fff;
    color: #ff5b24;
    color: var(--color-secondary);
  }

  .travel-rates__btn:first-child {
    margin-left: 0;
  }

  .travel-rates__btn-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .travel-rates__btn-text::before {
    content: '';
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 4px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .travel-rates__cont--selected .travel-rates__btn-text {
    color: #ff5b24;
    color: var(--color-secondary);
    font-weight: bold;
    cursor: default;
  }

  .travel-rates__btn-text--public_transport::before {
    background-image: url(~@/assets/tikktalk/imgs/in_person_assignment_icons/public_transport.svg);
    background-image: var(--image-assignment-in-person-public-icon);
  }

  .travel-rates__btn-text--car::before {
    background-image: url(~@/assets/tikktalk/imgs/in_person_assignment_icons/driving.svg);
    background-image: var(--image-assignment-in-person-car-icon);
  }

  .travel-rates__btn-text--no_travel::before {
    min-width: 22px;
    background-image: url(~@/assets/tikktalk/imgs/in_person_assignment_icons/default.svg);
    background-image: var(--image-assignment-in-person-default-icon);
  }

  .travel-rates__btn:hover .travel-rates__btn-text--public_transport::before,
  .travel-rates__btn--active .travel-rates__btn-text--public_transport::before {
    background-image: url(~@/assets/tikktalk/imgs/in_person_assignment_icons/public_transport_active.svg);
    background-image: var(--image-assignment-in-person-public-active-icon);
  }

  .travel-rates__btn:hover .travel-rates__btn-text--car::before,
  .travel-rates__btn--active .travel-rates__btn-text--car::before {
    background-image: url(~@/assets/tikktalk/imgs/in_person_assignment_icons/driving_active.svg);
    background-image: var(--image-assignment-in-person-car-active-icon);
  }

  .travel-rates__btn:hover .travel-rates__btn-text--no_travel::before,
  .travel-rates__btn--active .travel-rates__btn-text--no_travel::before {
    background-image: url(~@/assets/tikktalk/imgs/in_person_assignment_icons/default_active.svg);
    background-image: var(--image-assignment-in-person-default-active-icon);
  }

  .travel-rates__btn:hover,
  .travel-rates__btn--active {
    border: solid 1px #ff5b24;
    border: solid 1px var(--color-secondary);
    background-color: #ff5b24;
    background-color: var(--color-secondary);
    color: #fff;
  }
</style>
