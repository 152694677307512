// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/distance-line.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.distance-section__title[data-v-71461d97] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-bottom: 10px;\n}\n.distance-section__title span[data-v-71461d97] {\n  font-weight: bold;\n}\n.distance-section__edit-btn[data-v-71461d97],\n.distance-section__distance[data-v-71461d97],\n.distance-section__value[data-v-71461d97] {\n  padding-left: 25px;\n  color: #ccc;\n  font-size: 12px;\n}\n.distance-section__edit-btn[data-v-71461d97] {\n  text-decoration: underline;\n}\n.distance-section__edit-wrapper[data-v-71461d97] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.distance-section__save-btn[data-v-71461d97] {\n  max-width: 50px;\n  margin-left: 12px;\n}\n.distance-section__distance[data-v-71461d97] {\n  position: relative;\n  margin-left: 25px;\n  padding: 30px 20px;\n}\n.distance-section__distance[data-v-71461d97]::before {\n  content: '';\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  display: block;\n  width: 6px;\n  margin-right: 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 0 50%;\n  background-size: 5px auto;\n  background-repeat: no-repeat;\n}\n.distance-section__custom-error[data-v-71461d97] {\n  width: 100%;\n  padding-bottom: 10px;\n  padding-left: 25px;\n  color: #f04;\n  color: var(--color-error);\n  font-size: 12px;\n}\n.distance-section__tooltip[data-v-71461d97] {\n  z-index: 1;\n  -ms-flex-item-align: start;\n      align-self: flex-start;\n  width: 15px;\n  height: 15px;\n  margin-top: 3px;\n  margin-right: 10px;\n  font-size: 12px;\n  line-height: 15px;\n}\n.distance-section__tooltip[data-v-71461d97]::before {\n  content: '?';\n  position: absolute;\n  top: 0;\n  z-index: -1;\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  background-color: #000;\n  color: #fff;\n  text-align: center;\n}\n", ""]);
// Exports
module.exports = exports;
