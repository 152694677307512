(<template>
  <div class="distance-section">
    <div class="distance-section__row">
      <div class="distance-section__title">
        <sk-tooltip :item-text="getAddressFromTooltipText"
                    class="distance-section__tooltip" />
        <span>{{ $gettext('I travel from:') }}</span>
      </div>
      <template v-if="!showSearch">
        <p class="distance-section__value">{{ travelFromAddress }}</p>
        <button v-if="isEditable"
                class="distance-section__edit-btn"
                @click="showAddressSearch">{{ $gettext('Edit home address') }}</button>
      </template>
      <div v-if="showSearch"
           class="distance-section__edit-wrapper">
        <autocomplete-input :id="'mapsId'"
                            :placeholder="getAddressPlaceholder"
                            :preselected-value="address"
                            :class-name="'distance-section__sk-input'"
                            @placechanged="placeChangedHandler" />
        <button class="sk-btn sk-btn--default distance-section__save-btn"
                @click="saveAddress">{{ $gettext('Save') }}</button>
      </div>
    </div>

    <p v-if="fullAddressError || failedGetDistanceError"
       class="distance-section__custom-error">{{ fullAddressError || failedGetDistanceError }}</p>

    <div class="distance-section__distance">{{ distanceText }}</div>

    <div class="distance-section__row">
      <div class="distance-section__title">
        <sk-tooltip :item-text="getAddressToTooltipText"
                    class="distance-section__tooltip" />
        <span>{{ $gettext('I travel to:') }}</span>
      </div>
      <p class="distance-section__value">{{ travelToAddress }}</p>
    </div>
  </div>
</template>)

<script>
  import {mapActions, mapGetters} from 'vuex';
  import AutocompleteInput from '@/components/shared_components/AutocompleteInput';

  export default {
    components: {
      'autocomplete-input': AutocompleteInput
    },
    props: {
      isEditable: Boolean,
      showSearchField: Boolean
    },
    data() {
      return {
        address: '',
        autocomplete: '',
        fullAddressError: '',
        placeObj: {
          floor: '',
          buildingNumber: '',
          street: '',
          city: '',
          county: '',
          country: '',
          postcode: '',
          line1: '',
          lon: '',
          lat: ''
        }
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      ...mapGetters('AssignmentFlowStore', ['travelCostsPricing', 'travelFromAddress']),
      ...mapGetters('OneAssignmentStore', ['travelToAddress']),
      travelDistance() { return this.travelCostsPricing.travelDistance || 0; },
      distance() { return Number(this.travelDistance).toFixed(2); },
      getAddressPlaceholder() {
        return this.$gettext('Add your home address here');
      },
      haveMapObject() {
        return window && window.google && window.google.maps;
      },
      showSearch(state) {
        return !state.travelFromAddress || this.showSearchField;
      },
      failedGetDistanceError() {
        return !this.travelDistance ? this.$gettext('The system could not calculate the distance from the place of assignment to your home. Please enter the closest building to your address') : '';
      },
      getAddressFromTooltipText() {
        return this.$gettext('Enter your home address here and click “Save” we will save your home address for future assignments.');
      },
      getAddressToTooltipText() {
        return this.$gettext('This is destination address.');
      },
      distanceText() {
        const template = this.$gettext('%{distance} km distance between');
        return this.$gettextInterpolate(template, {distance: this.distance});
      },
      errorAddressText() {
        const template = this.$gettext('When you change your home address, you have to wait at least 6 months to change it again. If this is an emergency, and you have to change your address now, please contact <a href="mailto:%{email}" class="sk-link js-copy-to-clipboard">%{email}</a>.');
        return this.$gettextInterpolate(template, {email: this.$getString('domainData', 'subSupportEmail')});
      }
    },
    methods: {
      ...mapActions('EditProfileStore', ['updateUserProfile']),
      showAddressSearch() {
        this.address = this.travelFromAddress;
        this.prefillPlaceObj(this.travelCostsPricing.travelFromAddress);
        this.$emit('setshowsearchfield', true);
      },
      prefillPlaceObj(travelAddressObj) {
        this.placeObj.line1 = travelAddressObj.line_1 || '';
        this.placeObj.buildingNumber = travelAddressObj.line_1 || '';
        this.placeObj.street = travelAddressObj.line_1 || '';
        this.placeObj.city = travelAddressObj.city || '';
        this.placeObj.county = travelAddressObj.county || '';
        this.placeObj.postcode = travelAddressObj.postcode || '';
        this.placeObj.country = travelAddressObj.country || '';
        this.placeObj.lat = travelAddressObj.latitude || '';
        this.placeObj.lon = travelAddressObj.longitude || '';
      },
      placeChangedHandler(place) {
        this.clearPlaceInfo();
        this.setAddress(place.formatted_address);
        this.parsePlaceObj(place);
      },
      parsePlaceObj(place) {
        if (place
          && place.geometry
          && place.geometry.location
          && place.address_components
          && place.address_components.length) {
          this.placeObj.lat = place.geometry.location.lat();
          this.placeObj.lon = place.geometry.location.lng();
          for (const obj of place.address_components) {
            for (const type of obj.types) {
              switch (type) {
                case 'street_number':
                  this.placeObj.buildingNumber = obj.long_name;
                  break;
                case 'route':
                  this.placeObj.street = obj.long_name;
                  break;
                case 'floor':
                  this.placeObj.floor = obj.long_name;
                  break;
                case 'locality':
                case 'postal_town':
                  this.placeObj.city = obj.long_name;
                  break;
                case 'administrative_area_level_1':
                  this.placeObj.county = obj.long_name;
                  break;
                case 'country':
                  this.placeObj.country = obj.long_name;
                  break;
                case 'postal_code':
                  this.placeObj.postcode = obj.long_name;
                  break;
              }
            }
          }
          this.placeObj.line1 = `${this.placeObj.street} ${this.placeObj.buildingNumber}`;
        }
      },
      saveAddress() {
        if (this.isValidAddress()) {
          const form = new FormData();
          const string = 'interpreter[person]';
          form.append(`${string}[address][line_1]`, this.placeObj.line1);
          form.append(`${string}[address][line_2]`, this.placeObj.floor);
          form.append(`${string}[address][city]`, this.placeObj.city);
          form.append(`${string}[address][county]`, this.placeObj.county);
          form.append(`${string}[address][country]`, this.placeObj.country);
          form.append(`${string}[address][postcode]`, this.placeObj.postcode);
          form.append(`${string}[address][latitude]`, this.placeObj.lat);
          form.append(`${string}[address][longitude]`, this.placeObj.lon);
          this.$store.commit('OneAssignmentStore/startAssignmentProgress');
          this.updateUserProfile({id: this.userUid, form}).then(() => {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
            const travelObj = {
              travelFromLatitude: this.placeObj.lat,
              travelFromLongitude: this.placeObj.lon
            };
            this.$emit('onsave', travelObj);
          }).catch((error) => {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
            if (error
              && error.errors
              && error.errors.global
              && error.errors.global.indexOf('interpreter_address_change_cooldown') !== -1) {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.errorAddressText
              });
            }
          });
        } else {
          this.fullAddressError = this.$gettext('Please enter a full address');
        }
      },
      isValidAddress() {
        return this.placeObj.buildingNumber && this.placeObj.postcode && this.placeObj.street;
      },
      clearPlaceInfo() {
        this.placeObj = {
          floor: '',
          buildingNumber: '',
          street: '',
          city: '',
          county: '',
          country: '',
          postcode: ''
        };
        this.fullAddressError = '';
      },
      setAddress(value) {
        this.address = value;
        this.clearPlaceInfo();
      }
    }
  };
</script>

<style scoped>
  .distance-section__title {
    position: relative;
    display: flex;
    margin-bottom: 10px;
  }

  .distance-section__title span {
    font-weight: bold;
  }

  .distance-section__edit-btn,
  .distance-section__distance,
  .distance-section__value {
    padding-left: 25px;
    color: #ccc;
    font-size: 12px;
  }

  .distance-section__edit-btn {
    text-decoration: underline;
  }

  .distance-section__edit-wrapper {
    display: flex;
  }

  .distance-section__save-btn {
    max-width: 50px;
    margin-left: 12px;
  }

  .distance-section__distance {
    position: relative;
    margin-left: 25px;
    padding: 30px 20px;
  }

  .distance-section__distance::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 6px;
    margin-right: 10px;
    background-image: url(~@assets/imgs/undefined_imgs/distance-line.svg);
    background-position: 0 50%;
    background-size: 5px auto;
    background-repeat: no-repeat;
  }

  .distance-section__custom-error {
    width: 100%;
    padding-bottom: 10px;
    padding-left: 25px;
    color: #f04;
    color: var(--color-error);
    font-size: 12px;
  }

  .distance-section__tooltip {
    z-index: 1;
    align-self: flex-start;
    width: 15px;
    height: 15px;
    margin-top: 3px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 15px;
  }

  .distance-section__tooltip::before {
    content: '?';
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    text-align: center;
  }
</style>
